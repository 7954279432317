import React from 'react'
import { PatientPage, SEO } from "components";

const NotFoundPage = () => (
  <PatientPage pageClassName="page-not-found">
    <SEO pageTitle="Patient Page Not Found" />
    <div className="dfa-container gutter-left gutter-right">
      <h1>PAGE NOT FOUND!</h1>
      <p>This page doesn't exist.</p>
    </div>
  </PatientPage>

)

export default NotFoundPage
